<script setup>
import { useForm } from '@inertiajs/vue3';
import { inject, ref } from 'vue';

// Layouts
import PublicLayout from '@/Layouts/PublicLayout.vue';

// Components
import Button from '@/Components/Button/Button.vue';
import Pagination from '@/Components/Pagination/PaginationWebsite.vue';
import ShiftCard from '@/Components/Shift/ShiftCard.vue';
import ShiftsHeader from '@/Components/Shifts/ShiftsHeader.vue';
import ShiftsSidebar from '@/Components/Shifts/ShiftsSidebar.vue';

const show = ref(false);
const route = inject('route');

const props = defineProps({
    query: Object,
    positions: Object,
    locations: Object,
    cities: Array,
    shifts: Object,
});

const form = useForm({
    search: props.query.search ?? '',
    page: props.query.page ?? 1,
    cities: props.query.cities ?? '',
    positions: props.query.positions ?? '',
    location_ids: props.query.location_ids ?? [],
});

const getData = (resetPage = true) => {
    form.page = resetPage ? 1 : form.page;

    form.get(route('shifts.index'), {
        preserveState: true,
        preserveScroll: true,
        only: ['shifts', 'query'],
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

const setPage = (e) => {
    form.page = e;
    getData(false);
};
</script>

<template>
    <PublicLayout title="FLEXABLE Positions">
        <ShiftsHeader :title="$t('Shifts')" :shiftsTotal="shifts.total" />

        <div class="container pt-8">
            <div class="flex flex-col gap-8 lg:flex-row">
                <div class="lg:w-2/6">
                    <div class="lg:hidden" v-auto-animate>
                        <Button pink @click="getData, (show = !show)" class="min-h-10">
                            <div class="gap-4 flex-center">
                                <div>{{ $t('Filter') }}</div>
                            </div>
                        </Button>

                        <div class="p-6 mt-4 mb-10 rounded bg-blue-medium" v-if="show">
                            <ShiftsSidebar
                                :form="form"
                                :locations="locations"
                                :getData="getData"
                                :cities="cities"
                                :positions="positions"
                            />
                        </div>
                    </div>

                    <div class="hidden lg:block">
                        <ShiftsSidebar
                            :form="form"
                            :locations="locations"
                            :getData="getData"
                            :cities="cities"
                            :positions="positions"
                        />
                    </div>
                </div>

                <div class="lg:w-3/6">
                    <div class="flex flex-col justify-center text-base" v-if="!shifts.data.length">
                        <h2 class="text-[32px] md:leading-14 font-bold mb-2 uppercase">
                            {{ $t('No available shifts') }}
                        </h2>
                        <p>{{ $t('No results found.') }}</p>
                    </div>
                    <div v-else>
                        <div class="mb-4" v-for="shift in shifts.data" :key="shift.id">
                            <ShiftCard :shift="shift" />
                        </div>
                        <pagination
                            v-if="typeof shifts !== 'undefined'"
                            :links="shifts.links"
                            @setPage="setPage"
                            :pageData="shifts"
                            class="my-10"
                            :hideTotal="query.search != '' && query.search != null"
                        />
                    </div>
                </div>
            </div>
        </div>
    </PublicLayout>
</template>
